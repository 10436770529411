/* ------------------------------ */
.campaigns {
  padding: 75px 20px 110px 20px;
}

.campaigns__container__alt {
  margin-top: 100px;
}

.campaigns__title h2 {
  width: 100%;
  text-align: center;
  font-size: 36px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 57px;
}

.campaigns__image {
  width: 40%;
  height: 40%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaigns__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.campaigns__image img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.campaigns__content {
  padding: 45px;
  text-align: left;
}
.campaigns__content p {
  margin-top: 25px;
  color: #333333;
  font-size: 1.1rem;
  font-weight: 600;
}

.campaigns__content p span {
  margin-top: 25px;
  color: #ff914d;
  font-size: 1.6rem;
  font-weight: bolder;
}

@media (max-width: 500px) {
  .campaigns__image {
    width: 95%;
    height: 95%;
  }
  .campaigns__content {
    padding: 3px;
  }
}
