@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.button {
  padding: 0.8em 1.6em;
  font-size: 1.2rem;
  background-color: #ff914d;
  color: #fff;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: 0.3s ease;
}

.button:hover {
  background-color: #f28d4e;
  transform: scale(1.05);
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Blur variant */
.button__blur {
  border-radius: 15.822px;
  background: rgba(255, 145, 77, 0.1);
  box-shadow: 8.781px -8.781px 8.781px 0px rgba(194, 110, 59, 0.1) inset,
    -8.781px 8.781px 8.781px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(8.781266212463379px);
  color: #f28d4e;
}

.button__blur:hover {
  background-color: rgba(255, 145, 77, 0.127);
  transform: scale(1.05);
}
