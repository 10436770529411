.input-container {
  display: flex;
  flex-direction: column;
  color: var(--secondary);
  border-radius: 5px;
  padding: 0.4rem;
  border: 1px solid #86a0bc;
}

.input-label {
  font-size: 10px;
  color: var(--secondary);
}

.required {
  color: var(--primary);
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-base);
  background-color: white;
  transition: border-color var(--transition-base);
}

/* .input-wrapper.outlined {
  background-color: transparent;
  border: 1px solid var(--border-color);
}

.input-wrapper.filled {
  background-color: var(--accent);
} */

.input-wrapper.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.input-field {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px !important;
  /* background-color: rgba(217, 217, 217, 0.3) !important; */
  color: #666;
  resize: vertical;
  padding: 12px 0;
  margin-top: 0;
  margin-bottom: 0;
}

.input-wrapper.error .input-field {
  border-bottom-color: var(--error-color, var(--error));
}

.start-adornment,
.end-adornment {
  margin: 0 var(--spacing-small);
  color: var(--text-secondary);
}

.helper-text {
  font-size: 15px;
  margin-top: 4px;
}

.full-width {
  width: 100%;
}

/* Add these styles to AppInput.css */

.input-wrapper.dropdown {
  cursor: pointer;
}

.dropdown-container {
  position: relative;
  width: 100%;
}

.selected-value {
  font-size: var(--font-size-small) !important;
}

.options-list-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  top: 30%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  z-index: 5;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  transition: transform 3s ease-in-out;
}

.close-button {
  text-align: center;
  cursor: pointer;
}

.close-icon {
  font-size: 0.5rem;
  background-color: #02101f;
  color: white;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
}

.options-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border: none;
  z-index: 5;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.options-list li {
  padding: var(--spacing-small);
  background-color: var(--accent);
  margin: var(--spacing-large);
  padding-left: var(--spacing-xx-large);
  color: var(--text-primary);
  cursor: pointer;
}

.options-list li:hover {
  background-color: var(--primary);
  color: var(--accent);
}

.option-icon {
  width: 20px;
  height: 15px;
  object-fit: cover;
  margin-right: 8px;
}
