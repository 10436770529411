/* Add overflow hidden to fix double scroll bars */
body {
  overflow-x: hidden;
}

.about__banner {
  background: url(../../../Assets/Images/about__banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 580px;
}

.about__banner__cover {
  width: 100%;
  height: 100%;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about__banner__content h1,
span,
p {
  color: #fff;
}
.about__banner__content h1 {
  margin-bottom: 90px;
  margin-top: -40px;
  font-size: 35px;
  font-weight: bolder;
  text-transform: uppercase;
}

.about__banner__content span {
  font-size: 25px;
  font-weight: 400;
  padding: 0 20px;
}
.about__banner__content p {
  font-size: 20px;
  font-weight: 600;
}

.about__wrapper {
  /* width: 90%; */
  height: auto;
  margin-top: -110px;
  background: #50b1e9;
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 #0000008a;
}

.about__content__item {
  padding: 75px 40px;
  text-align: center;
}

.about__content__item h2 {
  text-transform: capitalize;
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}

.about__content__item p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #333 !important;
  font-weight: bold;
}

/* ------------------------------ */
.about__history {
  margin-top: -20px;
}

.about__history__title h2 {
  width: 100%;
  text-align: center;
  font-size: 48px;
  margin-top: 15%;
  color: #333333;
}

.about__history__image {
  width: 40%;
  height: 40%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__history__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.about__history__image img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.about__history__content {
  padding: 45px;
  text-align: left;
}
.about__history__content p {
  margin-top: 35px;
  color: #333333;
  font-size: 1.1rem;
  font-weight: 600;
}

/* -------------------------------- */

.about__goals_container h2 {
  text-align: center;
  color: #333333;
  font-size: 27px;
  margin-top: 70px;
}

/* .about__goals_container ol li {
  font-size: 19px;
  color: #333333;
  margin-top: 13px;
  font-weight: 500;
} */
.goal-head {
  color: #333333;
}
.goal-details {
  color: #333333;
}

/* ----------------------------- */
.about__achievements__section {
  height: 550px;
  width: 100%;
  background: url(../../../Assets/Images/our__achievements.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
}

.about__achievements__section .cover {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
}

.learn-more-button {
  position: absolute;
  right: 20px; /* Adjust as needed for padding from the right */
}

.about__achievements h2 {
  margin-top: 80px;
  margin-bottom: 30px;
  /* font-weight: 400; */
  color: #333333;
  text-align: center;
  font-size: 35px;
}

.center__content span {
  /* text-align: center; */
  font-size: 4rem;
  font-weight: bolder;
}

/* ----------------------------- */
.about__achievements h4 {
  font-weight: 400;
  color: #333333;
  text-align: center;
  font-size: 20px;
}

.about__achievements .skip {
  width: 75%;
  margin: 25px auto;
}
.button__group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  gap: 50px;
}

@media (max-width: 930px) {
  .about__content__item {
    padding: 30px 20px;
  }

  .about__banner__content span {
    font-size: 19px;
  }
}

@media (min-width: 1016px) {
  .about__history {
    display: block !important;
  }
  .alt__alt {
    display: none !important;
  }
}

@media (max-width: 1016px) {
  .about__history {
    display: none !important;
  }
  .alt__alt {
    display: block !important;
  }
}

@media (max-width: 500px) {
  .about__achievements .skip {
    width: 93%;
  }
  .button__group {
    flex-direction: column;
    gap: 20px;
  }

  .center__content span {
    font-size: 2.5rem !important;
  }
}
