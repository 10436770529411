.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #333;
  max-width: 95%;
  margin: 0 auto;
}
.header__container p {
  font-size: 20px;
}

.notification__icon {
  position: relative;
  display: inline-block;
}

.notification__badge {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
}

.header__icon {
  color: #000 !important;
  font-size: 25px;
  cursor: pointer;
  transition: 0.4s;
}
.header__icon:hover {
  transform: scale(1.2);
}
