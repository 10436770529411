.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  background-color: #001529;
  color: white;
  overflow-y: auto;
  min-height: 100vh;
}

.dashboardlogo {
  margin: 30px 0;
  text-align: center;
}

.nav-menu .nav-item {
  padding: 20px 25px;
  /* font-weight: bold; */
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
}

.nav-menu .nav-item.active {
  background-color: #ff7043;
}
.nav-menu .nav-item:hover {
  background-color: #ff7043;
  padding: 20px 15px;
}

/* .dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.programs-section {
  margin-top: 20px;
}

.program-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.program-card {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content {
  flex: 1;
}

.card-image {
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.get-involved-btn {
  background-color: #ff7043;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.get-involved-btn:hover {
  background-color: #f4511e;
} */
