.bookings__banner {
  background: url(../../../Assets/Images/bookings__banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 500px;
}

.bookings__banner__cover {
  width: 100%;
  height: 100%;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bookings__banner__content h1,
span {
  color: #fff;
}
.bookings__banner__content h1 {
  margin-bottom: 90px;
  margin-top: -10px;
  font-size: 35px;
  font-weight: bolder;
  text-transform: uppercase;
}

.bookings__banner__content span {
  font-size: 30px;
  font-weight: 400;
}

/* --------  */

.booking__card div {
  display: flex;
  width: 100%;
  height: 120px;
  padding: 0px 55px 0px 55px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  background: #ff914d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
}
/* --------------  */
.bookings__section {
  height: 600px;
  background: url(../../../Assets/Images/bookings__banner__alt.png) no-repeat
    center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  margin-top: 110px;
}

.bookings__section .cover {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  padding: 0 20px;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookings__center__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.bookings h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  /* font-weight: 400; */
  color: #333333;
  text-align: center;
  font-size: 35px;
}

.bookings__center__content span,
.tourism__products .details span {
  /* text-align: center; */
  font-size: 2.3rem;
  text-transform: uppercase;
  font-weight: bolder;
}

.bookings__center__content p,
.tourism__products .details p {
  /* text-align: center; */
  font-size: 1.5rem;
  color: #fff;
  font-weight: bolder;
  margin: 40px 0;
}

/* -----------------  */
.tourism__products .details {
  text-align: center;
  padding: 20px 0;
}
.tourism__products {
  border-radius: 30px;
  margin-top: 120px;
  margin-bottom: 80px;
  background: linear-gradient(180deg, #50b1e9 0%, #001525 100%);
  /* height: 500px; */
  padding: 80px 30px 0 0;
}

.tourism__products .products img {
  margin-bottom: 0 !important;
  transform: rotate(-7deg);
}

@media (max-width: 546px) {
  .bookings__banner__content span {
    font-size: 20px;
  }
  .bookings__center__content span,
  .tourism__products .details span {
    font-size: 25px;
  }
  .bookings__center__content p,
  .tourism__products .details p {
    font-size: 20px;
    font-weight: 400;
  }
}
