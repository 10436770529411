.get__involved__banner {
  background: url(../../../Assets/Images/get__involved__banner.png) no-repeat
    center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 500px;
}

.get__involved__banner__cover {
  width: 100%;
  height: 100%;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.get__involved__banner__content h1,
span {
  color: #fff;
}
.get__involved__banner__content h1 {
  margin-bottom: 90px;
  margin-top: -10px;
  font-size: 35px;
  font-weight: bolder;
  text-transform: uppercase;
}

.get__involved__banner__content span {
  font-size: 30px;
  font-weight: bolder;
}

/* -------------  */
/* ------------------------------ */
.come__eat {
  padding: 75px 20px 110px 20px;
  background: none;
}

.get__involved__container_alt {
  margin-top: 50px;
}
.get__involved__title h2 {
  width: 100%;
  text-align: center;
  font-size: 36px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 57px;
}

.get__involved__image {
  width: 40%;
  height: 40%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get__involved__image img {
  max-width: 600px;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.get__involved__image img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.get__involved__content {
  padding: 45px;
  text-align: left;
  /* margin-top: 15%; */
}
.get__involved__content,
.get__involved__content__alt p {
  margin-top: 15%;
  color: #333333 !important;
  font-size: 1.1rem;
  font-weight: 600;
}

/* .get__involved__content__alt p {
  margin-top: 18px;
} */

.get__involved__section,
.get__involved__section__alt {
  height: 600px;
  background: url(../../../Assets/Images/donate.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  padding: 0 !important;
  border-radius: 30px;
}

.get__involved__section__alt {
  background: url(../../../Assets/Images/cooperate__agreement.png) no-repeat
    center;
}

.get__involved__section .cover,
.get__involved__section__alt .cover {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  padding: 0 20px;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.get__involved__center__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
}

.get__involved h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  /* font-weight: 400; */
  color: #333333;
  text-align: center;
  font-size: 35px;
}

.get__involved__center__content span {
  /* text-align: center; */
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bolder;
}

.get__involved__center__content p {
  /* text-align: center; */
  font-size: 1.5rem;
  color: #fff;
  font-weight: bolder;
  margin: 40px 0;
}

@media (max-width: 768px) {
  .get__involved__banner__content span {
    font-size: 20px;
  }
  .get__involved__image {
    width: 90%;
    height: 90%;
  }
}

@media (max-width: 500px) {
  .get__involved h2 {
    font-size: 25px;
    padding: 0 15px;
  }
  .get__involved__content {
    padding: 0px 5px;
  }
  .get__involved__section,
  .get__involved__section__alt {
    background-attachment: fixed;
    /* border-radius: none !important; */
  }

  .get__involved__section__alt {
    background-attachment: fixed;
  }
  .get__involved__center__content {
    width: 98%;
  }
}
