/* Hero Section */
.home-hero {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  margin-bottom: 150px;
  gap: 2rem;
  padding: 0 0 0 35px;
}

/* Content Section */
.home-hero-content {
  max-width: 50%;
  text-align: left;
  margin: 0 auto;
}

.home-hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.4;
  text-transform: capitalize;
}

.home-hero-subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

/* Buttons Section */
.home-hero-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1rem;
}

.home-hero-btn {
  padding: 10px 20px;
  font-size: 0.8rem;
  /* font-weight: bold; */
  background-color: #ff7043;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.home-hero-btn:hover {
  background-color: #f28d4e;
  transform: scale(1.05);
}

/* Image Section */
.home-hero-image img {
  width: 100%;
  max-width: 600px; /* Adjust image size dynamically */
  height: auto;
  margin: 0 auto;
}

.eat-challenge-video {
  width: 100%;
  object-fit: cover;
  height: auto;
}
/* get involved section */
/* GetInvolved.css */

.get-involved-main-cont {
  display: flex;
  justify-content: center;
  margin: 100px 20px;
  padding: 0 20px; /* Add padding for better spacing on smaller screens */
}

.get-involved-container {
  background: linear-gradient(to bottom, #7ec9e3, #c7e7b4);
  padding: 80px 20px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 1200px; /* Restrict max width for large screens */
}

.get-involved-container h2 {
  font-size: 2rem; /* Scales dynamically */
  color: #ffffff;
  margin-bottom: 20px;
}

.get-involved-container p {
  color: #ff914d;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  margin: 30px 0 50px;
}

.actions {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Add gap between action items */
  margin-top: 2em;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  gap: 10px;
  max-width: 200px; /* Restrict width for consistency */
}

.action-item img {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.action-item img:hover {
  transform: scale(1.1);
}

.donate-home-details {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.donate-home-details:hover {
  text-decoration: none;
  transform: scale(1.05);
  color: #ff914d;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .get-involved-container h2 {
    font-size: 1.8rem;
  }

  .get-involved-container p {
    font-size: 1.3rem;
  }

  .actions {
    gap: 15px; /* Reduce gap between action items */
  }

  .action-item {
    max-width: 150px;
  }

  .action-item img {
    width: 70px;
  }

  .donate-home-details {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .get-involved-container {
    padding: 60px 15px;
  }

  .get-involved-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .actions {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .action-item {
    max-width: none;
  }
}

@media (max-width: 480px) {
  .get-involved-container h2 {
    font-size: 1.5rem;
  }

  .get-involved-container p {
    font-size: 1rem;
    margin: 20px 0 30px;
  }

  .action-item img {
    width: 60px;
  }

  .donate-home-details {
    font-size: 0.9rem;
  }
}

/* liberian school lunch */
.liberian-banner {
  background-image: url("../../../Assets/Images/liberian-flag.png");
  background-size: cover;
  background-position: center;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  position: relative;
}

.liberian-overlay {
  /* background-color: rgba(0, 0, 0, 0.5); Creates a semi-transparent overlay */
  text-align: center;
  color: white;
  padding: 20px;
  /* border-radius: 8px; */
}

.liberian-header {
  color: #f0f0f0;
  text-align: center;
  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.liberian-details {
  font-size: 1rem;
  margin-bottom: 50px;
}

.liberia-learn-more-button {
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #ff914d;
  padding: 18px 50px;
  border-radius: 15.822px;
  background: rgba(255, 145, 77, 0.1);
  box-shadow: 8.781px -8.781px 8.781px 0px rgba(194, 110, 59, 0.1) inset,
    -8.781px 8.781px 8.781px 0px rgba(255, 255, 255, 0.1) inset;
  /* backdrop-filter: blur(8.781266212463379px); */
  transition: background-color 0.3s ease;
}

.liberia-learn-more-button:hover {
  background-color: #e64a19;
  color: #ffffff;
  transform: scale(1.02);
}

/* BookingCard.css */
.booking-card-container {
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: linear-gradient(180deg, #50b1e9 0%, #3f85a2 99%);
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  gap: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.booking-content {
  flex: 1;
  padding: 20px;
  text-align: center; /* Center align on smaller screens */
}

.booking-content h2 {
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

.booking-button {
  padding: 15px 50px;
  background-color: #ff914d;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.booking-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.booking-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-image img {
  width: 100%;
  max-width: 450px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .booking-card {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .booking-content {
    padding: 0;
  }

  .booking-content h2 {
    font-size: 1.8rem;
  }

  .booking-button {
    padding: 12px 40px;
    font-size: 1rem;
  }

  .booking-image img {
    max-width: 300px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .booking-content h2 {
    font-size: 1.5rem;
  }

  .booking-button {
    padding: 10px 30px;
    font-size: 0.9rem;
  }

  .booking-image img {
    max-width: 250px;
  }
}

/* --------------------------  */

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .home-hero-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
}
@media (max-width: 836px) {
  .home-hero-title {
    font-size: 1.7rem;
    line-height: 1.2;
  }
  .home-hero-subtitle {
    font-size: 0.7rem;
    line-height: 1.3;
  }
}
@media (max-width: 797px) {
  .home-hero {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    height: 350px;
    padding: 10px 15px;
  }
  .home-hero-buttons {
    justify-content: center;
  }
  .home-hero-image img {
    display: none !important;
  }
  .home-hero-content {
    max-width: 90%;
    text-align: center;
  }

  .home-hero-title {
    font-size: 2.2rem;
    margin-top: 3rem;
  }

  .home-hero-subtitle {
    font-size: 1rem;
  }

  .home-hero-buttons {
    gap: 1rem;
  }

  .home-hero-btn {
    width: 100%; /* Full-width buttons for smaller devices */
    max-width: 300px;
  }

  .actions {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .home-hero-title {
    font-size: 2rem;
  }

  .home-hero-subtitle {
    font-size: 0.9rem;
  }

  .home-hero-image img {
    max-width: 500px;
  }
}

@media (max-width: 480px) {
  .home-hero {
    height: 400px;
    margin-bottom: 0 !important;
  }

  .home-hero-title {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  .home-hero-subtitle {
    font-size: 0.85rem;
    margin-bottom: 1rem;
  }
  .home-hero-content {
    max-width: 100%;
  }

  .home-hero-image img {
    max-width: 100%;
  }

  .home-hero-btn {
    font-size: 0.9rem;
    padding: 10px 18px;
  }
}
