@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif !important;
  background-color: #e6f7ff;
  overflow-x: hidden;
}

.main-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.main-logo-container {
  position: absolute;
  top: 5%;
  left: 5%;
}
.main-logo {
  width: 110px;
  height: 110px; /* Set height equal to width for a perfect circle */
  border: none;
  object-fit: contain;
  border-radius: 50%; /* Makes the element a circle */
  background-color: #e0f2fa;
  transition: transform 0.3s ease-in-out;
}
.main-logo:hover {
  cursor: pointer;
  border-color: #3498db;
  transform: scale(1.05);
}

.main-app {
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; */
  /*Style to position the main app content at the center of the app*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-page-content {
  text-align: center;
}

.main-page-title {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.main-button {
  width: 600px;
  padding: 26px;
  margin: 10px 0;
  background-color: #ff914d;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
}

.main-button:hover {
  background-color: #f08442;
  transform: scale(1.02);
}

.main-button-label {
  flex-grow: 1;
  text-align: center; /* Center the label text */
}

.ellipsis {
  margin-left: 10px; /* Space between label and ellipsis */
  font-size: 22px;
  font-size: 18px;
  transform: rotate(90deg);
}

/* responsiveness  */
@media (max-width: 700px) {
  .main-button {
    width: 400px;
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .main-app {
    width: 97%;
  }
  .main-button {
    width: 100%;
    padding: 16px;
  }
  .main-logo {
    width: 90px;
    height: 90px;
  }
  .main-logo-container {
    top: 2%;
    left: 2%;
  }
}
