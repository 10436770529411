.app__wrapper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .app__wrapper {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .app__wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .app__wrapper {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .app__wrapper {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .app__wrapper {
    max-width: 1320px;
  }
}

.image-fluid {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1016px) {
  .img__alt {
    display: none !important;
  }
  .img__alt_alt {
    display: block !important;
  }
}

@media (max-width: 1016px) {
  .img__alt {
    display: block !important;
  }
  .img__alt_alt {
    display: none !important;
  }
}
