.login-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 32px;
  border-bottom: 1px solid #333333;
  background-color: #ffffff;
}

.login-navbar-logo img {
  height: 60px;
}
/* back section */
.form-project-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
  background-color: #ffffff;
}
.form-project-btn {
  padding: 10px 35px;
  border: none;
  font-weight: 700;
  border-radius: 50px;
  color: #071d6a;
  background-color: #f8e2d7;
  transition: transform 0.3s ease-in-out;
}
.form-project-btn:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.form-back-btn-icon {
  color: #ff914d;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #f8e2d7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.form-btn-hero {
  padding: 10px;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}
.form-btn-hero:hover {
  cursor: pointer;
  transform: scale(1.1);
}
/* login section */

/* .login-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.login-left img {
  width: 100%;
  height: 30%;
  /* max-width: 100%;
  height: auto; */
}

.login-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 480px; */
}

.login-header {
  margin-bottom: 2rem;
  text-align: center;
}

.login-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.login-forms {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.login-heading {
  color: #ff7846;
  letter-spacing: 2px;
  line-height: 10px;
  margin-bottom: 1em;
}

.login-form-group {
  display: flex;
  flex-direction: column;
}

.login-input {
  /* width: 200%; */
  padding: 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(217, 217, 217, 0.3);
  /* border-radius: 0.5rem;
  background-color: #f8fafc; */
}

.forgot-password,
.create-color {
  align-self: flex-end;
  color: #f97316;
  text-decoration: none;
  font-size: 0.8rem;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #948f8f;
}

.divider span {
  padding: 0 1rem;
  color: #ff7846;
}

.social-login {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.social-login button {
  width: 100px;
  height: 40px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-btn-container-two {
  display: flex;
  justify-content: center;
  margin-top: 3em;
}

.login-btn {
  padding: 1rem;
  background-color: #f97316;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  width: 60%;
  transition: transform 0.3s ease-in-out;
}
.login-btn:hover {
  transform: scale(1.02);
}

.signup-prompt {
  text-align: center;
  font-size: 0.875rem;
}

.signup-prompt a {
  color: #f97316;
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 545px) {
  .form-project-container {
    align-items: start;
    padding: 15px;
  }
}
