.verifyemail__container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
}

.verifyemail__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
  /* outline: 1px solid red; */
}

.verify__img {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
}

.verify__img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.verifyemail__content h2 {
  color: #333;
  font-size: 33px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 40px 0 20px 0;
}

.verifyemail__container p {
  color: #333333c4;
  font-size: 14px;
  font-weight: 400;
  max-width: 65%;
}
.verifyemail__content button {
  margin: 20px 0;
  width: 80%;
  border-radius: 20px;
  padding: 0.8em 1.5em;
  font-size: 1.1rem;
}

.bottom__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  line-height: 75px;
  color: #ff914d;
  fill: rgba(255, 145, 77, 0.1);
  box-shadow: 4.667px -4.667px 4.667px 0px rgba(194, 110, 59, 0.1) inset,
    -4.667px 4.667px 4.667px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(4.666666507720947px);
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  left: 5%;
}

.main__logo__container {
  position: absolute;
  top: 5%;
  left: 5%;
}

.main__logo {
  width: 90px;
  height: 90px; /* Set height equal to width for a perfect circle */
  border: none;
  object-fit: contain;
  border-radius: 50%; /* Makes the element a circle */
  background-color: #e0f2fa;
  transition: transform 0.3s ease-in-out;
}
.main__logo:hover {
  cursor: pointer;
  border-color: #3498db;
  transform: scale(1.05);
}

@media (max-width: 546px) {
  .verifyemail__content {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .verify__img {
    max-width: 200px;
  }
  .main__logo {
    display: none !important;
  }
  .bottom__btn {
    bottom: 3% !important;
  }
}
