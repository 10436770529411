.programs__banner {
  background: url(../../../Assets/Images/programs__banner.png) no-repeat center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 550px;
}

.programs__banner__cover {
  width: 100%;
  height: 100%;
  background-color: #001525d7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.programs__banner__content h1,
span {
  color: #fff;
}
.programs__banner__content h1 {
  margin-bottom: 90px;
  margin-top: -10px;
  font-size: 35px;
  font-weight: bolder;
  text-transform: uppercase;
}

.programs__banner__content span {
  font-size: 30px;
  font-weight: bolder;
}
/* ------------------ */
.placeholder {
  margin: 70px -15px 70px 0;
}
.programs__icons__item {
  text-align: center;
}
.programs__icons__item img {
  border-radius: 50%;
}

/* ------------------------------ */
.come__eat {
  background-color: #c4e6f2;
  padding: 75px 20px 110px 20px;
  margin-bottom: -40px;
}
.coach__me {
  background-color: #fff;
  padding: 75px 20px;
}
.bridge__builders {
  /* background-color: #fff; */
  padding: 75px 20px;
}
.programs__history__title h2 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 57px;
}

.programs__history__image {
  width: 40%;
  height: 40%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.programs__history__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.programs__history__image img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.programs__history__content {
  padding: 45px;
  text-align: left;
}
.programs__history__content p {
  margin-top: 25px;
  color: #333333;
  font-size: 1.1rem;
  font-weight: 600;
}

@media (max-width: 500px) {
  .programs__history__content {
    padding: 10px;
  }
  .programs__history__image {
    width: 95%;
    height: 950%;
  }
}
