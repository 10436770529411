.donate-hero-section {
  background-image: url("../../../Assets//Images//donate-hero-image.png");
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  position: relative;
}
.donate-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
}

.donate-overlay {
  position: relative; /* Keeps the text above the overlay */
  text-align: center;
  color: white;
  padding: 20px;
  /* border-radius: 8px; */
}

.donate-header {
  color: #f0f0f0;
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.donate-details {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 50px;
}
/* FORM SECTION */
.donate-form-container {
  padding: 0 50px;
  padding-top: 100px;
  padding-bottom: 50px;
}
.container {
  /* max-width: 500px; */
  max-width: 60%;
  margin: 0 auto;
  padding: 100px 50px;
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.t-donate-heading {
  font-size: 30px;
  margin-bottom: 10px;
}

.t-donate-details {
  font-size: 18px;
  color: #555;
}

.donation-options {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
  gap: 20px;
}

.donation-option {
  background-color: #fff4ee;
  border: none;
  padding: 18px 30px;
  width: 30%;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}
.donation-option-two {
  background-color: #e6e3eb;
  border: none;
  padding: 18px 30px;
  width: 30%;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.donation-option:hover {
  background-color: #e0e0e0;
}

.donation-form {
  background-color: #fff4ee;
  padding: 50px 50px;
  border-radius: 8px;
}
.form-group-heading {
  font-size: 18px;
  color: #555;
  margin-bottom: 4em;
  font-family: 600;
}

.form-group {
  margin-bottom: 18px;
  color: #b0b0b0;
}

/* input[type="text"],
input[type="email"], */
.code-input,
select {
  width: 100%;
  padding: 28px;
  margin-top: 5px;
  border: 0 solid #ddd;
  border-radius: 5px;
  color: #b0b0b0;
  font-size: 17px;
}

input[type="checkbox"] {
  margin-right: 10px;
}
.donta-form-agreement {
  font-size: 16px;
  color: #555;
  margin: 2em;
}
/* .agreement {
  display: flex;
  align-items: center;
} */
.form-group.agreement {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 15px 0;
}

.form-group.agreement input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
}

.agreement-labels {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.agreement-labels label {
  cursor: pointer;
  color: #333;
}
.submit-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.submit-button {
  width: 70%;
  /* width: 100%; */
  background-color: #ff914d;
  color: white;
  padding: 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 4em;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f08442;
  transform: scale(1.02);
}
/* FAQ SECTION */
.faq-main-container {
  margin-bottom: 50px;
}
.faq-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 0 1rem;
  padding-bottom: 50px;
  /* background-color: #ffffff; */
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-size: 18px;
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #333;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f5f5f5;
}

.faq-question.active {
  background-color: #f0f0f0;
}

.faq-icon {
  font-size: 1.25rem;
  font-weight: bold;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #fff;
  padding: 0 1rem;
}

.faq-answer.active {
  max-height: 200px;
  padding: 1rem;
}
