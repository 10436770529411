.vol-hero-section {
  background-image: url("../../../Assets//Images//volunteer-page-image.png");
  background-size: contain; /* Changed from 'cover' to 'contain' */
  background-repeat: no-repeat; /* Added to prevent image repetition */
  background-position: center;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  position: relative;
}
.vol-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
}

.vol-overlay {
  position: relative; /* Keeps the text above the overlay */
  text-align: center;
  color: white;
  padding: 20px;
  /* border-radius: 8px; */
}

.vol-header {
  color: #f0f0f0;
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2em;
  margin-bottom: 40px;
}

.vol-details {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 50px;
}
/* FORM SECTION */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f8ff;
  padding: 100px 0;
}

.signup-card {
  background: white;
  padding: 4rem 9rem;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
}
.volunteer-login-btn {
  text-align: end;
}
.volunteer-login-btn a {
  color: #ff7846;
  text-decoration: none;
}

.signup-header {
  text-align: center;
  margin-bottom: 2rem;
}

.signup-header h2 {
  margin-top: 2em;
  margin-bottom: 0.5rem;
  color: #333;
}

/* .signup-header a {
  color: #ff7846;
  text-decoration: none;
} */

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.volunteer-input-box {
  width: 100%;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 16px;
  background: #f8f8f8;
}

.volunteer-input-box:focus {
  outline: none;
  border-color: #ff7846;
  background: white;
}

.password-group {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.volunteer-next-btn-cont {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.volunteer-next-button {
  width: 40%;
  padding: 15px;
  background-color: #ff7846;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
  transition: transform 0.3s ease-in-out;
}

.volunteer-next-button:hover {
  background-color: #ff6632;
  transform: scale(1.02);
}
